<template>
  <div class="searchPanel">
    <div class="searchBar">
      <div class="searchBarSpace" :style="{ height: appBarHeight }"></div>
      <div class="searchBarInner">
        <div class="inputWrapper">
          <img
            class="searchIcon"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/search.png"
            alt=""
          />
          <input
            ref="ipt"
            type="text"
            v-model="value"
            placeholder="请输入网格名称搜索"
          />
          <img
            v-if="showClear"
            @click="clrearValueClick"
            class="clearIcon"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/clear.png"
            alt=""
          />
        </div>
        <div class="cancelBtn" @click="handleCancel">取消</div>
      </div>
    </div>
    <div class="searchResult">
      <ul
        class="list"
        v-if="filterList.length && value"
        :style="{ paddingTop: appBarHeight }"
      >
        <li
          class="listItem"
          v-for="item in filterList"
          @click="handleListItemClick(item)"
          :key="item.parkCode"
        >
          <div v-html="itemName(item)"></div>
        </li>
      </ul>
      <div
        class="noData"
        v-if="filterList.length === 0 && value && !isLoading"
        :style="{ paddingTop: appBarHeight }"
      >
        <img
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/no_data.png"
          alt=""
        />
        <span>暂无数据</span>
      </div>
      <div
        class="history-list"
        v-if="!value.length"
        :style="{ paddingTop: historyPaddingTop }"
      >
        <div class="history-list-title">历史记录</div>
        <ul>
          <li
            v-for="item in historyList"
            @click="handleHistoryListItemClick(item)"
            :key="item.parkCode"
          >
            <div>{{ item.parkName }}</div>
            <div class="delete-history" @click.stop="deleteHistoryClick(item)">
              <img
                src="../assets/imgs/StationManagement/History-delete@2x.png"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getParksByQuery,
  querySearchHistory,
  deleteHistoryById,
  addSearchHistory,
} from '@/api/api';
import { debounce } from '@/utils/index';
export default {
  props: {
    appBarHeight: {
      default: '0rem',
    },
  },
  computed: {
    showClear() {
      return this.value !== '';
    },
    historyPaddingTop() {
      const top = this.appBarHeightSession + 0.16;
      return `${top}rem`;
    },
  },
  data() {
    return {
      value: '',
      filterList: [], // 筛选列表
      historyList: [], // 搜索历史
      noData: false,
      isLoading: true,
      appBarHeightSession:
        (sessionStorage.getItem('_appBarHeight') ?? 0) / 100 + 0.1,
    };
  },
  mounted() {
    this.$refs.ipt.focus();
    this.getHistoryList();
    // 使用防抖调后端联想查询
    this.$refs.ipt.addEventListener('input', debounce(this.getParkList, 500));
  },
  methods: {
    clrearValueClick() {
      this.value = '';
      this.filterList = [];
    },
    handleCancel() {
      this.value = '';
      this.$emit('onCancel');
    },
    handleListItemClick(item) {
      this.value = '';
      this.$emit('onSelect', item);
      addSearchHistory({
        parkId: item.id,
        source: 2,
      });
    },
    handleHistoryListItemClick(item) {
      this.value = '';
      this.$emit('onSelect', item);
    },
    // 删除历史记录
    deleteHistoryClick(item) {
      deleteHistoryById(item.historyId).then(() => {
        this.historyList = this.historyList.filter(
          (e) => e.parkCode !== item.parkCode
        );
        if (this.historyList.length === 0) {
          this.noData = true;
        }
      });
    },
    getParkList() {
      this.isLoading = true;
      getParksByQuery({ nameLike: this.value })
        .then((list) => {
          this.filterList = list;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getHistoryList() {
      querySearchHistory({ source: 2 }).then((list) => {
        this.historyList = list;
      });
    },
    itemName(item) {
      let str = this.value;
      let replaceName = `<span class='hight-keyword'>${str}</span>`;
      var reg = new RegExp(str, 'g');
      let result = item.parkName.replace(reg, replaceName);
      let address = item.pro;
      if (item.pro !== item.city) {
        address += ' / ' + item.city;
      }
      address += ' / ' + result;
      return address;
    },
  },
};
</script>

<style lang="scss" scoped>
.searchPanel {
  width: 100vw;
  // min-height: 100vh;

  .searchBar {
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: 9;

    .searchBarSpace {
      background: white;
    }
    .searchBarInner {
      padding: 0.16rem 0.16rem 0.26rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .inputWrapper {
      flex-grow: 1;
      width: 6.34rem;
      height: 0.64rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: #f6f7f8;
      border-radius: 0.08rem;

      .searchIcon {
        width: 0.28rem;
        height: 0.28rem;
        margin-left: 0.18rem;
        margin-right: 0.16rem;
      }

      input {
        height: 0.64rem;
        flex-grow: 1;
        font-size: 0.32rem;
        color: #000000;
        caret-color: #436eff;
        background: #f6f7f8;

        &::placeholder {
          font-size: 0.28rem;
          color: #bfc0cb;
          font-weight: normal;
        }
      }

      .clearIcon {
        width: 0.36rem;
        height: 0.36rem;
        position: absolute;
        top: 0.14rem;
        right: 0.16rem;
      }
    }

    .cancelBtn {
      line-height: 0.64rem;
      margin-left: 0.2rem;
      font-size: 0.32rem;
      color: #000000;
      height: 0.64rem;
      width: max-content;
    }
  }

  .searchResult {
    margin-top: calc(0.64rem + 0.26rem + 0.16rem);

    .list {
      .listItem {
        background: white;
        box-sizing: border-box;
        // padding: 0.32rem 0.4rem;
        font-size: 0.32rem;
        color: #000000;
        overflow: hidden;
        font-weight: 600;

        &:active {
          background: #f6f7f8;
        }

        div {
          margin: 0 0.4rem;
          box-sizing: border-box;
          height: 1.08rem;
          line-height: 1.08rem;
          border-bottom: 1px solid #ebebeb;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .history-list {
      padding-top: 16px;
      .history-list-title {
        font-size: 0.24rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        line-height: 0.44rem;
        padding-left: 0.34rem;
      }
      ul li {
        position: relative;
        line-height: 1.08rem;
        border-bottom: 1px solid #ebebeb;
        font-size: 0.32rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        margin-left: 0.96rem;
        padding-right: 0.58rem;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 0.28rem;
          height: 0.28rem;
          left: -0.62rem;
          background: url(../assets/imgs/StationManagement/History_Time@2x.png)
            no-repeat;
          background-size: 0.28rem 0.28rem;
        }
        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .delete-history {
          position: absolute;
          top: 0;
          right: 0;
          width: 0.84rem;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 0.16rem;
            height: 0.16rem;
          }
        }
      }
    }
    .noData {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 2.4rem;

      img {
        width: 3.2rem;
        height: 3.2rem;
      }

      span {
        font-size: 0.32rem;
        color: #222222;
      }
    }
  }
}
</style>
